import { Avatar, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import portrait from '../img/avatar.png'
import SocialMediaContainer from './SocialMediaContainer'

const styles = (theme) => ({
	splashPanel: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	avatar: {
		margin: theme.spacing.unit * 2,
		width: 300,
		height: 300,
		[theme.breakpoints.down('sm')]: {
			width: 125,
			height: 125,
		},
	},
	nameTitle: {
		fontFamily: theme.fontStyle.serif,
		margin: theme.spacing.unit * 2,
		color: theme.palette.primary.contrastText,
		fontSize: '6rem',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '3.5rem',
		},
	},
	nameSubtitle: {
		fontFamily: theme.fontStyle.serif,
		color: theme.palette.primary.text,
		fontSize: '2em',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem',
		},
	},
	textContainer: {
		display: 'inline-flex',
		flexDirection: 'column',
		margin: theme.spacing.unit * 2,
	},
	avatarPlusName: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	nameDivider: {
		backgroundColor: 'theme.palette.primary.text',
		margin: theme.spacing.unit,
	},
})

const AvatarAndName = (props) => {
	const { classes } = props
	return (
		<div className={classes.splashPanel}>
			<div className={classes.avatarPlusName}>
				<Avatar
					className={classes.avatar}
					alt='portrait'
					src={portrait}
					sizes='true'
				/>
				<Typography className={classes.nameTitle} component='h3' variant='h2'>
					{'Tyler Watts'}
				</Typography>
				<Typography
					className={classes.nameSubtitle}
					component='h5'
					variant='subtitle1'
					gutterBottom
				>
					{'Full Stack Web Developer in Nashville, TN'}
				</Typography>
				<SocialMediaContainer />
			</div>
		</div>
	)
}

AvatarAndName.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AvatarAndName)
