import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'

const styles = (theme) => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		margin: theme.spacing.unit,
		width: 345,
		height: 'auto',
		textAlign: 'center',
		justifyContent: 'space-between',
	},
	media: {
		height: 140,
		margin: theme.spacing.unit,
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	cardTitle: {
		fontFamily: theme.fontStyle.serif,
	},
	cardContent: {
		[theme.breakpoints.up('md')]: {
			height: '100%',
		},
	},
	button: {
		marginBottom: theme.spacing.unit,
	},
	actionArea: {
		margin: '0 auto',
		alignSelf: 'flex-end',
	},
})

export default withStyles(styles)(
	class ProjectCard extends Component {
		state = {
			raised: false,
		}

		toggleRaised = () => {
			this.setState({ raised: !this.state.raised })
		}

		render() {
			const {
				classes,
				projectTitle,
				projectText,
				projectRepo,
				projectImage,
				projectDemo,
			} = this.props
			return (
				<Card
					onMouseOver={this.toggleRaised}
					onMouseOut={this.toggleRaised}
					raised={this.state.raised}
					className={classes.card}
				>
					<CardActionArea href={projectDemo} target='_blank'>
						<CardMedia
							className={classes.media}
							image={projectImage}
							title={projectTitle}
						/>
						<CardContent className={classes.cardContent}>
							<Typography
								className={classes.cardTitle}
								gutterBottom
								variant='h5'
								component='h2'
							>
								{projectTitle}
							</Typography>
							<Typography component='p'>{projectText}</Typography>
						</CardContent>
					</CardActionArea>
					<CardActions className={classes.actionArea}>
						<Button
							color='secondary'
							fullWidth
							className={classes.button}
							variant='contained'
							href={projectRepo}
							target='_blank'
						>
							See the Github repo
						</Button>
					</CardActions>
				</Card>
			)
		}
	}
)
