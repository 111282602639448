import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import React, { Component } from 'react'
import ContentContainer from './components/ContentContainer'

const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
	},
	fontStyle: {
		serif: 'Bitter',
		sans: 'Roboto',
	},
	palette: {
		primary: {
			light: '#6d6d6d',
			main: '#424242',
			dark: '#1b1b1b',
			text: '#eeeeee',
		},
		secondary: {
			light: '#6effe8',
			main: '#1de9b6',
			dark: '#00b686',
			text: '#212121',
		},
	},
})

export default class App extends Component {
	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<ContentContainer />
			</MuiThemeProvider>
		)
	}
}
