import anonMsgBoardImage from '../img/anon-msg-board.png'
import bookLookup from '../img/bookLookup.png'
import choroplethImage from '../img/choropleth.png'
import notesAppImage from '../img/notes-app.png'
import paletteImage from '../img/palette-picker.png'
import scheduleImage from '../img/schedule-app.png'

export default {
	projects: [
		{
			id: 1,
			title: 'Book Lookup',
			description: `Google Books API proxy using TypeScript, React Router, and React Spring. Fetches data from Google Books API and displays results to the user.`,
			repo: 'https://github.com/tylermwatts/book-lookup',
			image: bookLookup,
			demo: 'https://book-lookup.herokuapp.com/',
		},
		{
			id: 2,
			title: 'Employee Schedule Web App',
			description: `I made this full stack web application to handle scheduling at my place of work. I needed the ability to show hours and assignments for each employee each day, to handle split shifts, and to color-code each block according to the employee's assignment for the day.`,
			repo: 'https://github.com/tylermwatts/schedule-client',
			image: scheduleImage,
			demo: 'https://warpfox-schedule-client.herokuapp.com/',
		},
		{
			id: 3,
			title: 'The Palette Picker',
			description:
				"This project was created as part of the Nashville freeCodeCamp Meetup's Project Nights. It is a tool created to allow website designers to see a live preview of color palette combinations.",
			repo: 'https://github.com/nashvillefcc/palettepicker',
			image: paletteImage,
			demo: 'https://nashvillefcc-palettepicker.glitch.me/',
		},
		{
			id: 4,
			title: 'Notes App',
			description:
				'A simple web app for taking notes. It uses React and Material-UI components for sleek(ish) styling. The body of each note supports Markdown courtesy of the marked package. localStorage is used to persist notes between sessions.',
			repo: 'https://github.com/tylermwatts/notes-app',
			image: notesAppImage,
			demo: 'https://warpfox-notes-app.glitch.me/',
		},
		{
			id: 5,
			title: 'Anonymous Message Board',
			description:
				"An anonymous message board full-stack web app built as completion criteria for freeCodeCamp's Information Security & QA projects section. Users can start new threads or reply to existing ones.",
			repo: 'https://github.com/tylermwatts/anonymous-message-board',
			image: anonMsgBoardImage,
			demo: 'https://warpfox-anon-msg-board.glitch.me/b/general/',
		},
		{
			id: 6,
			title: 'Choropleth Map Data Visualization',
			description:
				"A data visualization project using U.S. educational data to show, by county, the percentage of adults 25 or older with bachelor's degrees or higher.",
			repo: 'https://github.com/tylermwatts/choropleth-us-education',
			image: choroplethImage,
			demo: 'https://warpfox-choropleth-map.glitch.me/',
		},
	],
}
