import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import AboutMe from './AboutMe'
import AppHeader from './AppHeader'
import AvatarAndName from './AvatarAndName'
import ProjectCardContainer from './ProjectCardContainer'

const styles = (theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	text: {
		color: theme.palette.primary.text,
	},
	divider: {
		backgroundColor: theme.palette.primary.text,
		margin: theme.spacing.unit * 4,
		[theme.breakpoints.up('md')]: {
			marginRight: theme.spacing.unit * 12,
			marginLeft: theme.spacing.unit * 12,
		},
	},
})

const Container = (props) => {
	const { classes } = props
	return (
		<div className={classes.container}>
			<AppHeader />
			<AvatarAndName />
			<Divider className={classes.divider} />
			<AboutMe />
			<Divider className={classes.divider} />
			<ProjectCardContainer />
		</div>
	)
}

export default withStyles(styles)(Container)
