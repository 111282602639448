import { Grid, Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import expressLogo from '../img/expressLogo.svg'
import githubLogo from '../img/githubLogo.png'
import gitLogo from '../img/gitLogo.png'
import jsLogo from '../img/javascriptLogo.svg'
import materialuiLogo from '../img/material-ui-logo.svg'
import mongodbLogo from '../img/MongoDB_LogoStacked_White_RGB.svg'
import nodeLogo from '../img/nodeLogo.svg'
import reactLogo from '../img/reactIcon.svg'
import vscodeLogo from '../img/vsCodeLogo.svg'

const styles = (theme) => ({
	container: {
		margin: theme.spacing.unit * 2,
	},
	flexBoxes: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		textAlign: 'center',
		margin: theme.spacing.unit,
	},
	text: {
		fontFamily: theme.fontStyle.serif,
		color: theme.palette.primary.text,
		padding: '0 16px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '3rem',
		},
	},
	divider: {
		background: theme.palette.primary.text,
		margin: theme.spacing.unit * 2,
	},
	iconContainer: {
		display: 'flex',
		justifyContent: 'center',
		margin: theme.spacing.unit,
	},
	icon: {
		height: '30px',
		[theme.breakpoints.up('md')]: {
			height: '50px',
			margin: theme.spacing.unit,
		},
	},
})

const logos = [
	{ src: jsLogo, alt: 'js-logo' },
	{ src: reactLogo, alt: 'react-logo' },
	{ src: nodeLogo, alt: 'node-logo' },
	{ src: expressLogo, alt: 'express-logo' },
	{ src: mongodbLogo, alt: 'mongo-db-logo' },
	{ src: materialuiLogo, alt: 'material-ui-logo' },
	{ src: gitLogo, alt: 'git-logo' },
	{ src: githubLogo, alt: 'github-logo' },
	{ src: vscodeLogo, alt: 'vscode-logo' },
]

const TechStack = ({ classes }) => {
	return (
		<div className={classes.container}>
			<Hidden smDown>
				<div className={classes.flexBoxes}>
					{logos.map((l, i) => (
						<img
							key={`icon ${i}`}
							className={classes.icon}
							src={l.src}
							alt={l.alt}
						/>
					))}
				</div>
			</Hidden>
			<Hidden mdUp>
				<Grid className={classes.grid} spacing={8} container>
					{logos.map((l, i) => (
						<Grid key={`icon ${i}`} item xs={4}>
							<div className={classes.iconContainer}>
								<img className={classes.icon} src={l.src} alt={l.alt} />
							</div>
						</Grid>
					))}
				</Grid>
			</Hidden>
		</div>
	)
}

export default withStyles(styles)(TechStack)
