import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import projectJson from '../data/Projects.js'
import ProjectCard from './ProjectCard.js'

const styles = (theme) => ({
	root: {
		display: 'inline-flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
	text: {
		fontFamily: theme.fontStyle.serif,
		color: theme.palette.primary.text,
		padding: '0 16px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '3rem',
		},
	},
})

export default withStyles(styles)(
	class ProjectCardContainer extends Component {
		render() {
			const { projects } = projectJson
			const { classes } = this.props
			return (
				<>
					<Typography
						align='center'
						variant='h2'
						gutterBottom
						className={classes.text}
					>
						{'Check out some of my projects!'}
					</Typography>
					<div style={{ textAlign: 'center' }}>
						<div className={classes.root}>
							{projects.map((p) => {
								return (
									<ProjectCard
										key={p.id}
										projectDemo={p.demo}
										projectImage={p.image}
										projectTitle={p.title}
										projectText={p.description}
										projectRepo={p.repo}
									/>
								)
							})}
						</div>
					</div>
				</>
			)
		}
	}
)
