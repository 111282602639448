import { AppBar, Button, Modal, Toolbar, Typography } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Contact from './Contact'

const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	barTextNormal: {
		fontFamily: theme.fontStyle.serif,
		flexGrow: 1,
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	barTextSmall: {
		fontFamily: theme.fontStyle.serif,
		flexGrow: 1,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	paper: {
		position: 'relative',
		backgroundColor: theme.palette.primary.main,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4,
		outline: 'none',
		margin: '0 auto',
		top: '20%',
		textAlign: 'center',
		[theme.breakpoints.up('sm')]: {
			width: '35vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '60vw',
		},
	},
	button: {
		marginBottom: '10px',
	},
	appBar: {
		backgroundColor: '#9c9c9c',
	},
})

export default withStyles(styles)(
	class AppHeader extends React.Component {
		state = {
			open: false,
		}

		handleModalOpen = () => {
			this.setState({ open: true })
		}

		handleModalClose = () => {
			this.setState({ open: false })
		}

		render() {
			const { classes } = this.props
			return (
				<div className={classes.root}>
					<AppBar square={false} position='static' className={classes.appBar}>
						<Toolbar>
							<Typography
								className={classes.barTextNormal}
								variant='h4'
								color='inherit'
								noWrap={true}
							>
								{'Tyler Watts | Personal Portfolio'}
							</Typography>
							<Typography
								className={classes.barTextSmall}
								variant='h4'
								color='inherit'
								noWrap={true}
							>
								{'My Portfolio'}
							</Typography>
							<Button
								onClick={this.handleModalOpen}
								color='secondary'
								variant='contained'
								size='medium'
							>
								Contact
							</Button>
							<Modal
								aria-labelledby='contact-modal'
								aria-describedby='ways to contact this person'
								open={this.state.open}
								onClose={this.handleModalClose}
							>
								<div className={classes.paper}>
									<Contact />
								</div>
							</Modal>
						</Toolbar>
					</AppBar>
				</div>
			)
		}
	}
)
