import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import aboutMeText from '../data/aboutMeText'
import TechStack from './TechStack'

const styles = (theme) => ({
	paper: {
		width: '60vw',
		margin: theme.spacing.unit * 2,
		padding: theme.spacing.unit * 2,
	},
	typography: {
		fontFamily: theme.fontStyle.serif,
		padding: theme.spacing.unit * 3,
		color: theme.palette.primary.text,
		[theme.breakpoints.down('sm')]: {
			fontSize: '3rem',
		},
	},
	aboutMeText: {
		marginBottom: theme.spacing.unit * 3,
		marginLeft: theme.spacing.unit * 40,
		marginRight: theme.spacing.unit * 40,
		color: theme.palette.primary.text,
		[theme.breakpoints.down('lg')]: {
			marginLeft: theme.spacing.unit * 20,
			marginRight: theme.spacing.unit * 20,
		},
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing.unit * 3,
			marginRight: theme.spacing.unit * 3,
			fontSize: '1.25em',
		},
		aboutMeContainer: {
			[theme.breakpoints.up('sm')]: {
				display: 'inline-flex',
				justifyContent: 'center',
				maxHeight: '80vh',
			},
		},
		link: {
			color: theme.palette.primary.text,
			textDecoration: 'underline',
		},
	},
})

const AboutMe = ({ classes }) => {
	const { text1, text2, text3 } = aboutMeText
	return (
		<div className={styles.aboutMeContainer}>
			<Typography variant='h2' align='center' className={classes.typography}>
				{'About Me'}
			</Typography>
			<Typography
				variant='h5'
				component='p'
				align='left'
				gutterBottom
				className={classes.aboutMeText}
			>
				{text1}
			</Typography>
			<Typography
				variant='h5'
				component='p'
				align='left'
				gutterBottom
				className={classes.aboutMeText}
			>
				{text2}
			</Typography>
			<Typography
				variant='h5'
				component='p'
				align='left'
				gutterBottom
				className={classes.aboutMeText}
			>
				{text3}
			</Typography>
			<TechStack />
		</div>
	)
}

export default withStyles(styles)(AboutMe)
