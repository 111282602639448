import { FormControl, Input, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = theme => ({
  form: {
    display: 'flex'
  },
  textField: {
    fullWidth: {
      margin: theme.spacing.unit * 2
    }
  },
  cssInput: {
    margin: 'auto',
    color: theme.palette.primary.text,
    '&:before': {
      borderBottomColor: theme.palette.primary.text
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.text
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottomColor: theme.palette.primary.text
    }
  },
  cssLabel: {
    color: theme.palette.primary.text,
    '&$focused': {
      color: theme.palette.primary.text
    }
  },
  send: {
    backgroundColor: theme.palette.secondary.main
  },
  disabled: {},
  focused: {},
  error: {}
});

const Contact = ({ classes }) => {
  return (
    <form
      className={classes.form}
      name="contact"
      method="POST"
      data-netlify="true"
    >
      <FormControl style={{ flexGrow: 1 }}>
        <input type="hidden" name="form-name" value="contact" />
        <TextField
          className={classes.textField}
          label="Name"
          name="name"
          type="text"
          required
          fullWidth
          InputProps={{
            classes: { root: classes.cssInput }
          }}
          InputLabelProps={{
            classes: { root: classes.cssLabel, focused: classes.focused }
          }}
        />
        <TextField
          className={classes.textField}
          label="Email"
          name="email"
          type="email"
          required
          fullWidth
          InputProps={{
            classes: { root: classes.cssInput }
          }}
          InputLabelProps={{
            classes: { root: classes.cssLabel, focused: classes.focused }
          }}
        />
        <TextField
          className={classes.textField}
          label="Message"
          name="message"
          type="text"
          required
          fullWidth
          multiline
          rows="4"
          rowsMax="4"
          InputProps={{
            classes: { root: classes.cssInput }
          }}
          InputLabelProps={{
            classes: { root: classes.cssLabel, focused: classes.focused }
          }}
        />
        <br />
        <Input className={classes.send} type="submit" value="Send" />
      </FormControl>
    </form>
  );
};

export default withStyles(styles)(Contact);
